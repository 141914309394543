import React, { useEffect, useState} from 'react';
import {  Container } from 'reactstrap';
import LoginModal from '../../shared/components/Modals/LoginModal';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../../redux/actions/users';
import { getApiByFriendlyName } from '../../redux/actions/apis';


import { getPlanByApiAndOrganization } from '../../redux/actions/plans';
import { getImageLayouts } from '../../redux/actions/linkPreview';

import Tabs from './components/Tabs/Tabs';
import TabContentComponent from './components/Tabs/TabContent';
import ImageTemplateModal from './components/ImageTemplateModal/ImageTemplateModal';

import './linkPreview.css';

const LinkPreview = (props) => {
  const [ displayLoginModal, setDisplayLoginModal ] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [ activeTab, setActiveTab ] = useState('dashboard');

  useEffect(() => {
    if(!props.api && !props.apiError) {
      props.getApiByFriendlyName('Opengraphio');
    }
  }, [props.api])

  useEffect(() => {
    if (!props.user && !props.userLoading) {
      setDisplayLoginModal(true);
    }

    if (props.user && props.api.id && !props.plan.id && !props.planError) {
      props.getPlanByApiAndOrganization();
    }

  }, [props.api, props.user, props.plan.id, props.planError, props.apiError]);


  useEffect(() => {
    if(props.plan && props.plan.tierId && props.api && props.api.tiers){
      setCurrentPlan(props.api.tiers.find((tier) => tier.id === props.plan.tierId))
    }

  }, [props.plan, props.api])

  useEffect(() => {
    if(props.user) {
      setDisplayLoginModal(false);
    }
  }, [props.user]);

  useEffect(() => {
    if(props.imageLayouts.layouts.length === 0) {
      props.getImageLayouts();
    }
  }, []);

  return (
    <Container style={{maxWidth: '1320px'}}  className="dashboard">
      {displayLoginModal ? <LoginModal userState={props.userState} visible={displayLoginModal} formData={{ formData: props.formData }}/> : '' }
      {props.user && props.user.firstName && (<h3 style={{ maxWidth: '1320px'}} className='welcome-user'>Welcome, {props.user.firstName} &#127881;</h3>)}
      <ImageTemplateModal />
      <Tabs active={activeTab} onTabChange={setActiveTab}/>
      <TabContentComponent activeTab={activeTab} />
    </Container>
    )
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  userState: state.users,
  plan: state.plans.detailPlan,
  planError: state.plans.error,
  api: state.apis.detailApi,
  formData: state.form.log_in_form,
  currentOrganization: state.organization.currentOrg,
  imageLayouts: state.linkPreview.imageLayouts,
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
  getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
  getImageLayouts: () => dispatch(getImageLayouts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkPreview)
