import React, { useEffect, useState } from 'react';
import { Button, CardBody, Col, Row, Tooltip } from 'reactstrap';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { Link } from 'react-router-dom';
import { FaRegQuestionCircle } from 'react-icons/fa';

const ApiCreditsUsed = (props) => {
  const { currentCycleInformation } = props;

  const [ totalPlanRequests, setTotalPlanRequests ] = useState(0)
  const [ requestsUsed, setRequestsUsed ] = useState(0)
  const [ renewalDate, setRenewalDate ] = useState(new Date())
  const [requestTooltipOpen, setRequestTooltipOpen] = useState(false);

  const toggleRequestToolTip = () => setRequestTooltipOpen(!requestTooltipOpen);


  const getTotalCredits = (cycleObj) => {
    if (cycleObj.tier.data.rateLimits) {
      return cycleObj.tier.data.rateLimits[0].quantity
    } else if(cycleObj.tier.data.pricingScheme) {
      return cycleObj.tier.data.pricingScheme.steps[0].ceiling
    } else {
      return 0
    }
  }

  const calculatePercentageOfCreditsUsed = (total, used) => {
    return (used / total) * 100
  }

  const calculateTimeRemaining = (renewalDate) => {
    const today = new Date();

    if(today.getDate() === renewalDate.getDate()) {
      return 'Resets Today'
    } else {
      const renewal = new Date(renewalDate);
      const timeRemaining = renewal - today;
      const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

      return `Resets in ${daysRemaining} days`
    }
  }

  useEffect(() => {
    setRequestsUsed(currentCycleInformation.currentBillingCycle.totalCalls || 0)
    setTotalPlanRequests(getTotalCredits(currentCycleInformation))

    if(currentCycleInformation.currentBillingCycle.endDate) {
      setRenewalDate(new Date(currentCycleInformation.currentBillingCycle.endDate))
    }
  }, [currentCycleInformation]);

  return (

    <Row style={{ alignItems: 'center'}}>
      <Col>
        <CardBody style={{borderRadius: '20px'}}>
          <Row>
            <Col md={6}>
              <div className="card__title">
                <h3 className="subhead" style={{ lineHeight: 'unset', marginRight: '5px' }}>{requestsUsed.toLocaleString()} Requests Used</h3>
                <div className={'tooltip'} style={{ zIndex: 10}}>
                  <FaRegQuestionCircle size={16}/>
                  <span className={'tooltiptext'}>You have used {requestsUsed.toLocaleString()} of {totalPlanRequests.toLocaleString()} requests this billing cycle.</span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <p style={{textAlign: 'end', color: '#A0ABBB'}}>{calculateTimeRemaining(renewalDate)}</p>
            </Col>
          </Row>
          <ProgressBar value={requestsUsed} completedPercentage={calculatePercentageOfCreditsUsed(totalPlanRequests, requestsUsed)}/>
          <Row>
            <Col md={6}>
              <Link to={'/apis/billing/Opengraphio'} className="btn btn-outline-primary">Upgrade Plan</Link>
            </Col>
            <Col md={6}>
              <p style={{textAlign: 'end', color: '#A0ABBB'}}>{requestsUsed.toLocaleString()} / {totalPlanRequests.toLocaleString()} Total Requests</p>
            </Col>
          </Row>
        </CardBody>
      </Col>
    </Row>
  )
}

export default ApiCreditsUsed;
