import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import AccountIcon from 'mdi-react/AccountIcon';
import { Button } from 'reactstrap';
import validate from './validateUserDetails';
import {editUser} from '../../../redux/actions/users';
import {Col, Card, CardBody} from 'reactstrap';



const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class EditUserDetailsForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }


  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signUpClicked = () => {
    // check for errors then sign up
    if(this.props.formState.syncErrors){

    } else {
      // edit user
      this.props.editUser();
    }
  };

  render() {
    return (
      <Col md={6} xl={6}>
        <Card>
          <CardBody>
            <form className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountIcon/>
                  </div>
                  <Field
                    name="firstName"
                    component={renderField}
                    type="text"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountIcon/>
                  </div>
                  <Field
                    name="lastName"
                    component={renderField}
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="form__form-group disabled">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <p>
                    {this.props.user && this.props.user.email}
                  </p>
                </div>
              </div>
              <Button className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.signUpClicked}>Update Profile</Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.edit_user_details_form,
  user: state.users.user,
  initialValues: {...state.users.user}
});

const mapDispatchToProps = (dispatch) => ({
  editUser: () => dispatch(editUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'edit_user_details_form',
  validate
})(EditUserDetailsForm));
