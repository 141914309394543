import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import './TabsStyles.css';

const Tabs = ({active, onTabChange}) => {

  return (
    <Nav
      className={'dashboard--tabs_container'}
      pills
    >
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'dashboard' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('dashboard')}
        >
          Link Preview Tool
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'layouts' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('layouts')}
        >
          Layouts
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default Tabs;
