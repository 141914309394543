import React from 'react';
import { Button, CardBody, Col } from 'reactstrap';
import { toggleImageLayoutModal } from '../../../../redux/actions/linkPreview';
import { connect } from 'react-redux';

const ImageDesigns = (props) => {
  //Dispatch Props Below
  const { toggleImageLayoutModal } = props;

  //State Props Below
  const { imageLayouts } = props;

  const renderCreateDesignCard = (layout) => (
    <CardBody style={{ borderRadius: '20px', marginBottom: '20px'}}>
      <Col md={12}>
        <div className={'new-design-card'}>
          <img
            src={layout.imageUrl}
            alt={layout.alt || layout.name}
            className="new-design-image-preview"
          />
          <h4>New Design</h4>
          <Button className={'new-design-card-cta'} onClick={() => toggleImageLayoutModal(true)} color={'primary'}>Create New Design</Button>
        </div>
      </Col>
    </CardBody>
  );

  return (
    <div>
      {imageLayouts.layouts[0] ? renderCreateDesignCard(imageLayouts.layouts[0]) : null}
      <CardBody style={{ borderRadius: '20px', marginBottom: '50px'}}>
        <div className={'image-layout_container'}>
          {imageLayouts.layouts.map((layout, i) => (
            <div
              // onClick={() => handleLayoutSelect(layout)}
              key={`layout-${i}`}
              className="image-layout_card"
            >
              <img src={layout.imageUrl} alt={layout.alt || layout.name || `Layout-${i}`} className="new-design-image-preview" />
              <p>{layout.name}</p>
            </div>
          ))}
        </div>
      </CardBody>
    </div>
  )
}


const mapStateToProps = (state) => ({
  imageLayouts: state.linkPreview.imageLayouts,
});

const mapDispatchToProps = (dispatch) => ({
  toggleImageLayoutModal: (open) => dispatch(toggleImageLayoutModal(open)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ImageDesigns);
