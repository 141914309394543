import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';

import './ImageTemplateModal.css';
import { IoClose } from 'react-icons/io5';
import {
  createImageTemplate,
  getImageLayouts, toggleImageLayoutModal,
} from '../../../../redux/actions/linkPreview';
import { connect } from 'react-redux';

const ImageTemplateModal = (props) => {
  const { imageLayoutModal, toggleImageLayoutModal: toggle, imageLayouts, createImageTemplate, getImageLayouts } = props;

  const { open: visible, loading } = imageLayoutModal;

  const [step, setStep] = React.useState(1);
  const [selectedLayout, setSelectedLayout] = React.useState(null);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [ctaText, setCtaText] = useState('');

  const [ color, setColor ] = useState('#000000');
  const [ showColorPicker, setShowColorPicker ] = useState(true);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleLayoutSelect = (layout) => {
    setSelectedLayout(layout);
    nextStep();
  };

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  const renderEditableField = ( field ) => {
    switch (field) {
      case 'title':
        return (
          <>
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'siteText':
        return (
          <>
            <label>Site Text</label>
            <textarea
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              rows={3}
              className="form-textarea"
            />
          </>
        );
      case 'imageUrl':
        return (
          <>
            <label>Image URL</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'ctaText':
        return (
          <>
            <label>CTA Text</label>
            <input
              type="text"
              value={ctaText}
              onChange={(e) => setCtaText(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'color':
        return (
          <div style={{ position: 'relative'}} >
            {/*{showColorPicker ? <div className={'image-template-color-picker_container'}><HexColorPicker color={color} onChange={setColor} /></div> : null}*/}
            <p onClick={toggleColorPicker} className={'input-button'}>{color}</p>
          </div>
        );
      default:
        return null;
    }

  }

  useEffect(() => {
    if(imageLayouts.layouts.length === 0) {
      getImageLayouts();
    }
  }, []);


  const step1 = (
    <div className={'image-template-modal_step-1_container'}>
      <h3>Choose from one of the following layouts</h3>
      <div className="image-templates-container">
        {imageLayouts.layouts.map((layout, i) => (
          <div
            onClick={() => handleLayoutSelect(layout)}
            key={i}
            className="image-template"
          >
            <img src={layout.imageUrl} alt={layout.alt || `Layout-${i}`} className="template-image" />
          </div>
        ))}
      </div>
    </div>
  );

  const step2 = () => (
    <div className="image-template-modal_step-2_container">
      {/* Form Column */}
      <h3>Edit Template</h3>
      <div className={'image-template-modal_step-2_form_container'}>
      <div className="editor-form">
        <h4>Edit Text</h4>
        <div className="form">
          {selectedLayout.params.map((field, i) => (
            <div key={i} className="form__form-group">
              {renderEditableField(field)}
            </div>
          ))}
        </div>
      </div>

      {/* Image Display Column */}
      <div className="image-display">
        {selectedLayout && (
          <img
            src={selectedLayout.imageUrl}
            alt={selectedLayout.alt || selectedLayout.name}
            className="selected-template-image"
          />
        )}
      </div>
      </div>
    </div>
  );

  return (
    <Modal fullscreen isOpen={visible} toggle={toggle} className={`image-template-modal ${step === 2 ? 'large' : ''}`}>
      <div className="modal__close-btn" onClick={() => toggle(false)}>
        <IoClose size={26} />
      </div>
      {imageLayouts.loading && <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div> }
      {step === 1 && step1}
      {step === 2 && step2()}
      <div className={'image-template-modal_button_container'}>
        {step === 2 && <Button color="primary" onClick={prevStep}>Back</Button>}
        {step === 2 && <Button color="primary" onClick={() => createImageTemplate({ layoutId: selectedLayout.id, title, image: imageUrl, siteText: subtitle, ctaText })}>Create</Button>}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  imageLayouts: state.linkPreview.imageLayouts,
  imageLayoutModal: state.linkPreview.imageLayoutModal,
});

const mapDispatchToProps = (dispatch) => ({
  getImageLayouts: () => dispatch(getImageLayouts()),
  toggleImageLayoutModal: (open) => dispatch(toggleImageLayoutModal(open)),
  createImageTemplate: (templateInfo) => dispatch(createImageTemplate(templateInfo))
})


export default connect(mapStateToProps, mapDispatchToProps)(ImageTemplateModal);
