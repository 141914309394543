import React from 'react';
import { Button, Modal } from 'reactstrap';

import './modals.scss';
import { setOrganizationalDashboardDefaultView } from '../../../redux/actions/preferences';
import { connect } from 'react-redux';


const OrganizationDefaultRoleModal = ({displayModal, user, setOrganizationalDashboardDefaultView}) => {
  const [ selectedRole, setSelectedRole ] = React.useState('');

  return (
      <Modal
        className="organization-default-dashboard-modal"
        isOpen={displayModal.open}
      >
        <div style={{ position: 'relative'}}>
          {/*<div className="modal__close-btn" onClick={() => toggleModal(false)}>*/}
          {/*  <IoClose size={36} />*/}
          {/*</div>*/}
          {user && user.firstName ? (
            <h4 className="welcome-user">Welcome, {user.firstName} 🎉</h4>
          ) : (
            <h4 className="welcome-user">Welcome! 🎉</h4>
          )}
          <div className="functionality-section">
            <p className="functionality-text">What role best suits you?</p>
          </div>
          <div className="options-container">
          {displayModal.loading ? (
            <div style={{ margin: 'auto'}} className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <div onClick={() => setSelectedRole('DEVELOPER')} className={`option-card ${selectedRole === 'DEVELOPER' ? ' selected' : ''}`}>
                <span className="option-icon"></span>
                <p className="option-text">I am a Developer</p>
              </div>
              <div onClick={() => setSelectedRole('MARKETER')} className={`option-card ${selectedRole === 'MARKETER' ? ' selected' : ''}`}>
                <span className="option-icon"></span>
                <p className="option-text">I am a Marketer</p>
              </div>
            </>
          )}
          <Button disabled={!selectedRole || displayModal.loading} onClick={() => setOrganizationalDashboardDefaultView(selectedRole)} color="primary" className="submit-button">
            Submit
          </Button>
        </div>
        </div>
      </Modal>
  )
}

const mapStateToProps = (state) => ({
  displayModal: state.preferences.dashboard.displayModal,
});

const mapDispatchToProps = (dispatch) => ({
  setOrganizationalDashboardDefaultView: (view) => dispatch(setOrganizationalDashboardDefaultView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDefaultRoleModal)
