import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Button } from 'reactstrap';
import validateLogin from './validateRegistration';
import { signUpUser } from '../../../redux/actions/users';

import { LiaLaptopCodeSolid } from 'react-icons/lia';
import { BsMegaphone } from 'react-icons/bs';

const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

//DOCME: In the future this could be used on registration page to allow users to choose their role sooner
//
// const renderDeveloperCard = ({ input }) => {
//   return (
//     <div onClick={() => input.onChange('DEVELOPER')} className={`role-choice-card ${input.value === 'DEVELOPER' ? 'selected' : ''}`}>
//       <LiaLaptopCodeSolid size={36}/>
//       <p className="role-choice-card-text">Developer</p>
//     </div>
//   );
// };
//
// const renderMarketerCard = ({ input }) => {
//   return (
//     <div onClick={() => input.onChange('MARKETER')} className={`role-choice-card ${input.value === 'MARKETER' ? 'selected' : ''}`}>
//       <BsMegaphone size={36}/>
//       <p className="role-choice-card-text">Marketer</p>
//     </div>
//   );
// };


class RegistrationForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signUpClicked = (e) => {
    e.preventDefault()
    // check for errors then sign up
    if(this.props.formState.syncErrors){

    } else {
      this.props.flipForm()
      if(this.props.invitationId){
        this.props.signUpUser(this.props.invitationId, this.props.location || '')
      } else {
        this.props.signUpUser('', this.props.location || '')
      }
    }
  };


  render() {
    const { handleSubmit } = this.props;
    return (
      <>
        <form className="form">
          {/*<div className="form__form-group">*/}
          {/*<span className={'form__form-group-label'}>Choose your role</span>*/}
          {/*<div className={'role-choice-container'}>*/}
          {/*  <Field*/}
          {/*    name="role"*/}
          {/*    component={renderDeveloperCard}*/}
          {/*  />*/}
          {/*  <Field*/}
          {/*    name="role"*/}
          {/*    component={renderMarketerCard}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*</div>*/}
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              {/*<div className="form__form-group-icon">*/}
              {/*  <MailRuIcon />*/}
              {/*</div>*/}
              <Field
                name="email"
                component={renderField}
                type="text"
                placeholder="Email"
              />
            </div>
          </div>
          {/*{!this.props.invitationId && <div className="form__form-group">*/}
          {/*  <span className="form__form-group-label">Organization Name</span>*/}
          {/*  <div className="form__form-group-field">*/}
          {/*    /!*<div className="form__form-group-icon">*!/*/}
          {/*    /!*  <MailRuIcon />*!/*/}
          {/*    /!*</div>*!/*/}
          {/*    <Field*/}
          {/*      name="organizationName"*/}
          {/*      component={renderField}*/}
          {/*      type="text"*/}
          {/*      placeholder="Organization Name"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <FormText>Optional - Will default to `your-email-org`</FormText>*/}
          {/*</div>}*/}
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component={renderField}
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="passwordConfirm"
                component={renderField}
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password Confirmation"
              />
            </div>
          </div>
          <Button type="submit" className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.signUpClicked}>Sign Up</Button>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.registration_form
});

const mapDispatchToProps = (dispatch) => ({
  signUpUser: (payload, location) => dispatch(signUpUser(payload, location))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'registration_form',
  validate: validateLogin
})(RegistrationForm));
