import React from 'react';
import { Col, Row, TabPane, TabContent } from 'reactstrap';
import LinkPreviewTool from '../LinkPreviewTool/LinkPreviewTool';
import LayoutDesigns from '../ImageDesigns';


const TabContentComponent = ({ activeTab  }) => {

  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId="dashboard">
        <Row>
          <Col md={12}>
            <LinkPreviewTool />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId={'layouts'}>
        <Row>
          <Col md={12}>
            <LayoutDesigns />
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  )
}

export default TabContentComponent;
