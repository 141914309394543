import React, {Component} from 'react';
import {Col, Row, Container, Button, Modal} from 'reactstrap';
import {connect} from 'react-redux';
import EditUserDetailsForm from './components/EditUserDetailsForm';
import {getLoggedInUser, clearUserAlert, deleteUserByOrganizationId, deleteUser} from '../../redux/actions/users';
import {Alert} from 'reactstrap';
import PasswordResetForm from './components/PasswordResetForm';
import DeleteUserForm from './components/deleteUserForm';



class Profile extends Component {
  state = {
    isOpen: false
  }

  render(){
    let alert = this.props.alert || {};
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title profile-title">User Profile Page &#128113;</h3>
          </Col>
          <Col>
            <Alert color={alert.color} className="alert--bordered" isOpen={!!alert.message} toggle={this.props.clearUserAlert}>
              <div className={'padding5'}>
                <span>{alert.message}</span>
              </div>
            </Alert>
            <Row>
              <EditUserDetailsForm/>
              <PasswordResetForm/>
            </Row>
            <Row style={{justifyContent:'center'}}>
            <DeleteUserForm deleteUser={this.props.deleteUser} />
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  userLoading: state.users.loading,
  alert: state.users.alert
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
  clearUserAlert: () => dispatch(clearUserAlert()),
  deleteUser: () => dispatch(deleteUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
