import {
    GET_LOGGED_IN_USER_SUCCESS,
    USER_LOG_IN_FAIL,
    USER_LOG_IN_SUCCESS,
    USER_SIGN_UP_FAIL,
    USER_SIGN_UP_SUCCESS,
    CLEAR_USER_ALERT,
    REQUEST_VERIFICATION_EMAIL_SUCCESS,
    USER_LOGOUT_SUCCESS,
    REQUEST_PASSWORD_EMAIL_SUCCESS,
    EDIT_USER_SUCCESS,
    GET_LOGGED_IN_USER_FAIL,
    SEND_SUPPORT_EMAIL_SUCCESS,
    REQUEST_PASSWORD_EMAIL_FAIL,
    GET_USER_INVOICES_SUCCESS,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS,
    GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER,
    DELETE_USER_BY_ORG_ID,
    DELETE_USER_BY_ORG_ID_SUCCESS,
    DELETE_USER_BY_ORG_ID_FAIL,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS,
    GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION,
    GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS,
    GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL,
    GET_LATEST_PLAN_DETAILS,
    GET_LATEST_PLAN_DETAILS_SUCCESS,
    SET_DEFAULT_ORGANIZATION_SUCCESS,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL, USER_SIGN_UP, GET_LOGGED_IN_USER
} from '../actionTypes';
import {SetGoogleAnalyticsUserId} from '../../shared/utils/google-analytics/index';


const initialState = {
    user: null,
    alert: null,
    loading: true,
    invoices: [],
    currentPeriodRequestsCount: 0,
    currentBillingCycle: {
        loading: false,
        errorMessage: '',
        start: null,
        end: null,
        totalCalls: {
            loading: false,
            errorMessage: '',
            count: 0
        },
    },
    usageInfo: {
        loading: false,
        error: '',
        group_by_day: {},
        group_by_Type: {}
    }
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOG_IN_SUCCESS:
            return {...state, user: action.payload, loading: false};
        case USER_LOG_IN_FAIL:
            return {...state, alert: {message: action.payload.details.message, color: 'danger'}};
        case USER_SIGN_UP:
            return {...state, loading: true};
        case USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false,
                alert: {
                    message: 'Sign Up was successful, please check your email for confirmation link. If you do not see it please check your spam folder.',
                    color: 'success'
                }
            };
        case USER_SIGN_UP_FAIL:
            return {...state, loading: false, alert: {message: action.payload.details.message, color: 'danger'}};
        case GET_LOGGED_IN_USER:
            return {...state, loading: true};
        case GET_LOGGED_IN_USER_SUCCESS:
            SetGoogleAnalyticsUserId(action.payload.id);
            return {...state, user: action.payload, loading: false};
        case GET_LOGGED_IN_USER_FAIL:
            return {...state, user: null, loading: false};
        case CLEAR_USER_ALERT:
            return {...state, alert: null};
        case REQUEST_VERIFICATION_EMAIL_SUCCESS:
            return {...state, alert: {message: 'Email was sent, please check your inbox', color: 'success'}};
        case USER_LOGOUT_SUCCESS:
            return {...state, user: null};
        case REQUEST_PASSWORD_EMAIL_SUCCESS:
            return {...state, alert: {message: 'Email was sent, please check your inbox', color: 'success'}};
        case REQUEST_PASSWORD_EMAIL_FAIL:
            return {...state, alert: {message: action.payload.details.message, color: 'danger'}};
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                },
                alert: { message: 'Profile was updated successfully', color: 'success' }
            };
        case SEND_SUPPORT_EMAIL_SUCCESS:
            return {
                ...state,
                alert: {message: 'We have received your support email and will contact you soon.', color: 'success'}
            };
        case GET_USER_INVOICES_SUCCESS:
            return {...state, invoices: action.payload};
        case GET_USAGE_INFORMATION_FOR_ORGANIZATION:
            return {...state, usageInfo: {...state.usageInfo, loading: true }};
        case GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS:
            return {...state, usageInfo: {loading: false, ...action.payload}};
        case GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL:
            return {...state, usageInfo: {loading: false, error: action.payload}};
        case GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS:
            return {...state, currentPeriodRequestsCount: action.payload};
        case DELETE_USER:
            return {...state, loading: true};
        case DELETE_USER_SUCCESS:
            return {...state, loading: false, user: null};
        case DELETE_USER_FAIL:
            return {...state, loading: false, alert: {message: action.payload.details.error, color: 'danger'}};
        case DELETE_USER_BY_ORG_ID:
            return {...state, loading: true};
        case DELETE_USER_BY_ORG_ID_SUCCESS:
            return {...state, loading: false, user: null};
        case DELETE_USER_BY_ORG_ID_FAIL:
            return {...state, loading: false};
        case GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                currentBillingCycle: {
                    loading: true,
                    ...state.currentBillingCycle,
                }};
        case GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL:
            return {
                ...state,
                currentBillingCycle: {
                    loading: false,
                    errorMessage: action.payload,
                    ...initialState.currentBillingCycle,
                }};
        case GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    loading: false,
                    errorMessage: null,
                    start: action.payload.current_period_start,
                    end: action.payload.current_period_end,
                }}
        case GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    totalCalls: {
                        ...state.currentBillingCycle.totalCalls,
                        loading: true,
                    }
                }};
        case GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    loading: false,
                    errorMessage: null,
                    totalCalls: {
                        loading: false,
                        errorMessage: '',
                        count: action.payload,
                    }
                }
            }
        case GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    totalCalls: {
                        count: 0,
                        loading: false,
                        errorMessage: action.payload,
                    }
                }
            }
        case GET_LATEST_PLAN_DETAILS:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    loading: true,
                }
            }
        case GET_LATEST_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                currentBillingCycle: {
                    ...state.currentBillingCycle,
                    loading: false,
                    errorMessage: null,
                    start: action.payload.startDate,
                    end: action.payload.endDate,
                }
            }
        case SET_DEFAULT_ORGANIZATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    defaultOrgId: action.payload.defaultOrgId,
                }
            }
        default:
            return state;
    }
};


export default users;
