import {
  EDIT_LINK_PREVIEW_META_DATA,
  EDIT_UPDATED_RESULTS,
  GENERATE_SEO_TAGS,
  GENERATE_SEO_TAGS_ERROR,
  GENERATE_SEO_TAGS_SUCCESS,
  GET_IMAGE_LAYOUTS,
  GET_IMAGE_LAYOUTS_FAIL,
  GET_IMAGE_LAYOUTS_SUCCESS,
  MAKE_DEBUGGER_API_CALL,
  MAKE_DEBUGGER_API_CALL_ERROR,
  MAKE_DEBUGGER_API_CALL_SUCCESS,
  MAKE_LINK_PREVIEW_CALL,
  MAKE_LINK_PREVIEW_CALL_ERROR,
  MAKE_LINK_PREVIEW_CALL_SUCCESS,
  SCROLL_TO_RESULTS,
  SET_ACTIVE_LINK_PREVIEW_TAB,
  SET_LAST_CALLED_URL,
  SET_SEO_AI_META_TAGS, TOGGLE_IMAGE_LAYOUT_MODAL,
  TOGGLE_MARKETING_SIGN_UP_MODAL,
  TOGGLE_MISSING_TAGS_ALERT
} from '../actionTypes';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import { opengraphApiBase } from '../../constants/config';
import { createSegmentEvent } from '../segment';


export const handleEditMetaData = (e) => {
  const { name, value } = e.target;

  return (dispatch, getState) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name, value });
    const results = getState().linkPreview.results;

    if(results && results.hybridGraph) {
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, [name]: value } , openGraph: {  ...results.openGraph, [name]: value }, htmlInferred: {  ...results.htmlInferred, [name]: value } } });
    }

    if(name === 'image') {
      dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value });
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, image: { url: value } }, openGraph: {  ...results.openGraph, image: { url: value } }, htmlInferred: {  ...results.htmlInferred, image: { url: value } } } });
    }
  }
};

export const handleEditMetaDataFromResults = (name, value) => {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name, value });
    const results = getState().linkPreview.results;

    if(results && results.hybridGraph) {
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, [name]: value } , openGraph: {  ...results.openGraph, [name]: value }, htmlInferred: {  ...results.htmlInferred, [name]: value } } });
    }


    if(name === 'image[url]') {
      dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value });
    }
  }
}

export const setLinkPreviewUrl = (url) => {
  return (dispatch) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
  }
}

export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_LINK_PREVIEW_TAB, tab });
  }
}

export const toggleMarketingSignUpModal = (open) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open });
  }
}

export const generateAiMetaData = () => {
  return (dispatch, getState) => {

    const previewUrl = getState().linkPreview.form.url;

    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = `${opengraphApiBase}site/ai/seo/${encodeURIComponent(previewUrl)}?app_id=${apiKey}&cache_ok=false`;

    //TODO: Create google Analytics Event
    // TrackGoogleAnalyticsEvent('debugToolCall','Usage Metrics', 'debugToolCall', url)

    dispatch({type: GENERATE_SEO_TAGS})

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if(results.error) {
          if(results.error.code === 102) {
            dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open: true})
            dispatch({ type: GENERATE_SEO_TAGS_ERROR, payload: results.error })
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
          dispatch({ type: GENERATE_SEO_TAGS_ERROR, payload: results.error.message })
        } else {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'title', value: results.response.title  });
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'description', value: results.response.description });
          dispatch({ type: GENERATE_SEO_TAGS_SUCCESS, payload: { title: results.response.title, description: results.response.description } } );
          }

      })
      .catch((err) => {
        console.log('Error trying to preview a link.', err);
      })
  }
}

export const constSetSeoAiMetaData = (results) => {
  return (dispatch) => {
    dispatch({ type: SET_SEO_AI_META_TAGS, results });
  }
}

export function makeLinkPreviewCall(e){
  e.preventDefault()
  return (dispatch, getState) => {

    const previewUrl = getState().linkPreview.form.url;

    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = `${opengraphApiBase}link-preview/${encodeURIComponent(previewUrl)}?app_id=${apiKey}&cache_ok=false`;

    dispatch({type: MAKE_LINK_PREVIEW_CALL})
    //TODO: Create google Analytics Event
    // TrackGoogleAnalyticsEvent('debugToolCall','Usage Metrics', 'debugToolCall', url)

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if(results.error) {
          if(results.error.code === 102) {
            dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open: true})
            dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
            return
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
        }
        if(results.code && results.code === -1012) {
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.message })
          return
        }
        console.log('HERE ARE THE RESULTS', results.hybridGraph);

        dispatch({ type: MAKE_LINK_PREVIEW_CALL_SUCCESS, results });

        const { image, title, description, url, type } = results.hybridGraph || {};

        if( !results.openGraph.title || !results.openGraph.description) {
          dispatch({ type: TOGGLE_MISSING_TAGS_ALERT, open: true})
        }

        if (image) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: image });
        }
        if (title) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'title', value: title });
        }
        if (description) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'description', value: description });
        }
        if(url) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
        }
        if(type) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'type', value: type });
        }
      })
      .catch((err) => {
        console.log('Error trying to preview a link.', err);
        dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err })
      })
  }
}

export const dismissMissingTagsAlert = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MISSING_TAGS_ALERT, open: false })
  }
}

export const dismissErrorAlert = () => {
  return (dispatch) => {
    dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: null })
  }
}

export const getImageLayouts = () => {
  return (dispatch, getState) => {
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = 'http://localhost:3011/api/v1/layouts';

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        dispatch({ type: GET_IMAGE_LAYOUTS_SUCCESS, results });
      })
      .catch((err) => {
        dispatch({ type: GET_IMAGE_LAYOUTS_FAIL });
        console.log('Error trying to get image layouts.', err);
      })
  }
}

export const createImageTemplate = (templateFields) => {
  return (dispatch, getState) => {
    let user = getState().users.user
    let organizationId = user.defaultOrgId;

    const { layoutId, image, title, siteText, ctaText } = templateFields;
    const encodedImageUrl = encodeURIComponent(image);
    let url = `http://localhost:3011/api/v1/image-gen/${organizationId}/${layoutId}/${encodedImageUrl}/${title}/${siteText}/${ctaText}`;

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        console.log('Results from creating a template', results);
        dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: results.imageUrl });
      })
      .catch((err) => {
        console.log('Error trying to create a template.', err);
      })

  }

}

export const toggleImageLayoutModal = (open) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_IMAGE_LAYOUT_MODAL, open });
  }
}
