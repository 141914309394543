import {apiClient} from '../../constants/clients';
import {
    CLEAR_USER_ALERT,
    DELETE_USER,
    DELETE_USER_BY_ORG_ID,
    DELETE_USER_BY_ORG_ID_FAIL,
    DELETE_USER_BY_ORG_ID_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS,
    EDIT_USER,
    EDIT_USER_FAIL,
    EDIT_USER_SUCCESS,
    GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION,
    GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_FAIL,
    GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS,
    GET_LOGGED_IN_USER,
    GET_LOGGED_IN_USER_FAIL,
    GET_LOGGED_IN_USER_SUCCESS,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL,
    GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS,
    GET_USER_INVOICES,
    GET_USER_INVOICES_FAIL,
    GET_USER_INVOICES_SUCCESS,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS,
    REQUEST_PASSWORD_EMAIL,
    REQUEST_PASSWORD_EMAIL_FAIL,
    REQUEST_PASSWORD_EMAIL_SUCCESS,
    REQUEST_VERIFICATION_EMAIL,
    REQUEST_VERIFICATION_EMAIL_FAIL,
    REQUEST_VERIFICATION_EMAIL_SUCCESS,
    USER_LOG_IN,
    USER_LOG_IN_FAIL,
    USER_LOG_IN_SUCCESS,
    USER_LOGOUT,
    USER_LOGOUT_FAIL,
    USER_LOGOUT_SUCCESS,
    USER_SIGN_UP,
    USER_SIGN_UP_FAIL,
    USER_SIGN_UP_SUCCESS,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL,
    GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS,
    GET_LATEST_PLAN_DETAILS,
    GET_LATEST_PLAN_DETAILS_SUCCESS,
    SET_CURRENT_ORG,
    GET_USER_ORGANIZATIONS_SUCCESS,
    GET_USER_ORGANIZATIONS_FAIL,
    GET_USER_ORGANIZATIONS,
} from '../actionTypes';
import {createSegmentEvent} from '../segment';
import {getTotalApiCallsForUser} from './apiLogs';
import { makeCookieObj } from '../../constants/shared';

import * as Sentry from '@sentry/react';

import moment from 'moment';
import { getOrganizationCurrentCycleInformation, getOrganizationStripeSubscription, getOrganizationStripeInfo } from './organization';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import { setDashboardView, toggleDashboardModal } from './preferences';

export function loginUser() {
    return (dispatch, getState) => {
        const payload = {
            email: getState().form.log_in_form.values.email.toLowerCase(),
            password: getState().form.log_in_form.values.password,
        };
        dispatch({type: USER_LOG_IN});
        apiClient.login(payload)
            .exec((err, result, fullResult) => {
                if (err) {
                    dispatch({type: USER_LOG_IN_FAIL, payload: err});
                } else {
                    const defaultOrg = getDefaultOrganizationForUser(result);
                    const isMarketing = result.isMarketing ? 'yes' : 'no';

                    let meta =  {
                        analytics: createSegmentEvent('identify', {
                            userId: result.id,
                            traits: {
                                email: result.email,
                                firstName: result.firstName,
                                lastName: result.lastName,
                                isMarketing,
                                planName: result.organizations[0].plans[0].tier.name,
                                planPrice: result.organizations[0].plans[0].tier.priceMonthly,
                                signedUp: result.createdAt,
                                lastSignIn: new Date().toISOString()
                            }
                        })
                    };
                    dispatch({type: USER_LOG_IN_SUCCESS, payload: result, meta});
                    dispatch({type: GET_USER_ORGANIZATIONS_SUCCESS, payload: result.organizations })
                    dispatch({type: SET_CURRENT_ORG, payload: defaultOrg})

                    if(defaultOrg && defaultOrg.id) {
                        dispatch(getOrganizationCurrentCycleInformation(defaultOrg.id))
                        dispatch(getOrganizationStripeInfo(defaultOrg.id))

                        if(defaultOrg.plans && defaultOrg.plans[0] && defaultOrg.plans[0].stripeSubscriptionId){
                            dispatch(getOrganizationStripeSubscription(defaultOrg.plans[0].stripeSubscriptionId))
                        }

                    }
                }
            });
    };
}

export function getLoggedInUser() {
    return (dispatch) => {
        dispatch({type: GET_LOGGED_IN_USER})
        dispatch({type: GET_USER_ORGANIZATIONS})
        apiClient.currentUser()
            .exec((err, user) => {
                if (err) {
                    dispatch({type: GET_LOGGED_IN_USER_FAIL, payload: err})
                } else if (!user) {
                    dispatch({type: GET_LOGGED_IN_USER_FAIL, payload: {message: 'No logged in user'}})
                    dispatch({type: GET_USER_ORGANIZATIONS_FAIL, payload: {message: 'No logged in user'}})
                } else {
                     const defaultOrg = getDefaultOrganizationForUser(user);

                    if(defaultOrg && defaultOrg.id) {
                        dispatch(getOrganizationCurrentCycleInformation(defaultOrg.id))

                        if(defaultOrg.plans && defaultOrg.plans[0] && defaultOrg.plans[0].stripeSubscriptionId){
                            dispatch(getOrganizationStripeSubscription(defaultOrg.plans[0].stripeSubscriptionId))
                        }
                    }

                    const cookies = makeCookieObj();
                    //Check for any cookies that Start with AC_
                    //If they exist, then
                    //Remove the AC_ and use the rest of the string to set the field in the user object
                    user.linkPreviewCall = cookies['AC_linkPreviewCall'] ? 'yes' : 'no';
                    user.linkPreviewPopupViewed = cookies['AC_linkPreviewPopupViewed'] ? 'yes' : 'no';
                    const linkPreviewChosenFeatures = cookies['AC_linkPreviewChosenFeatures'];

                    const isMarketing = user.isMarketing ? 'yes' : 'no';
                    let meta =  {
                        analytics: createSegmentEvent('identify', {
                            userId: user.id,
                            traits: {
                                email: user.email,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                isMarketing,
                                planName: user.organizations[0].plans[0].tier.name,
                                planPrice: user.organizations[0].plans[0].tier.priceMonthly,
                                signedUp: user.createdAt,
                                linkPreviewCall: user.linkPreviewCall,
                                linkPreviewPopupViewed: user.linkPreviewPopupViewed,
                                lastSignIn: new Date().toISOString(),
                                linkPreviewChosenFeatures
                            }
                        })
                    };

                    dispatch({type: GET_LOGGED_IN_USER_SUCCESS, payload: user, meta})
                    dispatch({type: GET_USER_ORGANIZATIONS_SUCCESS, payload: user.organizations })
                    dispatch({type: SET_CURRENT_ORG, payload: defaultOrg})
                    if(defaultOrg.defaultDashboard) {
                        dispatch(setDashboardView(defaultOrg.defaultDashboard))
                    } else {
                        dispatch(toggleDashboardModal())
                    }
                }
            })
    }
}

export function signUpUser(invitationId, location) {
    return (dispatch, getState) => {
        const payload = {
            email: getState().form.registration_form.values.email.toLowerCase(),
            password: getState().form.registration_form.values.password,
            organizationName: getState().form.registration_form.values.organizationName || '',
            invitationId: invitationId || null,
        };
        dispatch({type: USER_SIGN_UP});
        apiClient.createUser(payload, location)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: USER_SIGN_UP_FAIL, payload: err});
                } else {
                    let meta =  {
                        analytics: createSegmentEvent('identify', {
                            userId: result.id,
                            traits: {
                                verificationEmailSent: 'yes',
                                verificationEmailSentAt: new Date().toISOString()
                            }
                        })
                    };

                    TrackGoogleAnalyticsEvent('verificationEmailSent', 'Sign Up Flow', 'verificationEmailSent', 'Email Sign Up')
                    dispatch({type: USER_SIGN_UP_SUCCESS, payload: result, meta});
                }
            });
    };
}

export function getAllUsers() {
    return (dispatch) => {
        apiClient.getUsers()
            .exec((err, result) => {

            })
    }
}

export function clearUserAlert() {
    return {type: CLEAR_USER_ALERT}
}

export function resendVerificationEmail() {
    return (dispatch, getState) => {
        dispatch({type: REQUEST_VERIFICATION_EMAIL});
        dispatch(clearUserAlert());
        const payload = {
            email: getState().form.log_in_form.values.email,
        };
        apiClient.requestVerificationEmail(payload)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: REQUEST_VERIFICATION_EMAIL_FAIL})
                } else {
                    dispatch({type: REQUEST_VERIFICATION_EMAIL_SUCCESS})
                }
            })
    }
}

export function logoutUser() {
    return (dispatch) => {
        dispatch({type: USER_LOGOUT});
        apiClient.logout()
            .exec((err, result) => {
                if (err) {
                    dispatch({type: USER_LOGOUT_FAIL, payload: err})
                } else {
                    dispatch({type: USER_LOGOUT_SUCCESS})
                }
            })
    }
}

export function requestPasswordResetEmail() {
    return (dispatch, getState) => {
        dispatch({type: REQUEST_PASSWORD_EMAIL})
        apiClient.requestPasswordReset({email: getState().form.log_in_form.values.email.toLowerCase()})
            .exec((err, result) => {
                if (err) {
                    dispatch({type: REQUEST_PASSWORD_EMAIL_FAIL, payload: err})
                } else {
                    dispatch({type: REQUEST_PASSWORD_EMAIL_SUCCESS, payload: result})
                }
            })
    }
}

export function editUser() {
    return (dispatch, getState) => {
        dispatch({type: EDIT_USER});
        let payload = getState().form.edit_user_details_form.values;
        payload.id = getState().users.user.id;
        //if password && password.length === 0 delete password
        payload.password && payload.password.length === 0 && console.log('password is empty')
        apiClient.updateUser(payload)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: EDIT_USER_FAIL, payload: err})
                } else {
                    dispatch({type: EDIT_USER_SUCCESS, payload: result})
                }
            })
    }
}

export function editUserPassword() {
    return (dispatch, getState) => {
        dispatch({type: EDIT_USER});
        let payload = getState().form.password_reset_form.values;
        payload.id = getState().users.user.id;
        apiClient.updateUser(payload)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: EDIT_USER_FAIL, payload: err})
                } else {
                    dispatch({type: EDIT_USER_SUCCESS, payload: result})
                }
            })
    }
}

export function getUserInvoices() {
    return (dispatch, getState) => {
        dispatch({type: GET_USER_INVOICES});
        let currentOrganization = getState().organization.currentOrg.information;

        let organizationId = currentOrganization.id;

        if (getState().admin.spyOrganization && getState().admin.spyOrganization.id) {
            organizationId = getState().admin.spyOrganization.id
        }

        if (!organizationId) {
            Sentry.captureException(`Not making request. No Org Id Found for user, ${getState().users.user}`)
            return
        }

        apiClient.getInvoicesForOrganization(organizationId)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: GET_USER_INVOICES_FAIL, payload: err})
                } else {
                    dispatch({type: GET_USER_INVOICES_SUCCESS, payload: result.data})
                }
            })

    }
}


export function getUsageInformationForOrganization(dateRange) {
    return (dispatch, getState) => {
        dispatch({type: GET_USAGE_INFORMATION_FOR_ORGANIZATION});
        let user = getState().users.user;
        if(!user){
            return
        }
        let organizationId = user.defaultOrgId;

        if (getState().admin.spyOrganization && getState().admin.spyOrganization.id) {
            organizationId = getState().admin.spyOrganization.id
        }

        apiClient.getUsageInformationForOrganization(organizationId)
            .setQueryParams({dateRange})
            .exec((err, result) => {
                if (err) {
                    dispatch({type: GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL, payload: err})
                } else {
                    dispatch({type: GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS, payload: result})
                }
            })

    }
}

export function getCountOfCallsInCurrentPeriodForOrganization() {
    return (dispatch, getState) => {
        dispatch({type: GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION});
        let user = getState().users.user;
        let organizationId = user.defaultOrgId;

        if (getState().admin.spyOrganization && getState().admin.spyOrganization.id) {
            organizationId = getState().admin.spyOrganization.id
        }

        apiClient.getCountOfRequestsFromCurrentPeriodForOrganization(organizationId)
            .exec((err, result) => {
                if (err) {
                    dispatch({type: GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_FAIL, payload: err})
                } else {
                    dispatch({type: GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS, payload: result})
                }
            })

    }
}

export function deleteUser() {
    return dispatch => {
        dispatch({type: DELETE_USER})
        apiClient.deleteUser()
            .exec((err, result) => {
                if (err) {
                    dispatch({type: DELETE_USER_FAIL, payload: err})
                } else {
                    dispatch({type: DELETE_USER_SUCCESS, payload: result})
                }
            })
    }
}

export function deleteUserByOrganizationId(){
    return (dispatch, getState) => {
        let user = getState().users.user || {};
        let organizationId = user.defaultOrgId;
        dispatch({type: DELETE_USER_BY_ORG_ID})
        apiClient.deleteOrganization(organizationId)
            .exec((err, result) => {
                if(err){
                    dispatch({type: DELETE_USER_BY_ORG_ID_FAIL, payload: err})
                } else {
                    dispatch({type: DELETE_USER_BY_ORG_ID_SUCCESS, payload: result})
                }
            })
    }
}

export function getUserLatestStripeSubscription() {
    return (dispatch, getState) => {
        let user = getState().users.user || {};
        let tiers = getState().apis.detailApi.tiers || [];
        let stripeCustomerId = user && user.organizations && user.organizations[0] && user.organizations[0].stripeCustomerId;

        const userPlanId = user && user.organizations && user.organizations[0] && user.organizations[0].plans &&  user.organizations[0].plans[0] && user.organizations[0].plans[0].id

        if(!userPlanId){
            return
        }

        let userOnFreeTier = tiers.some(tier => tier.priceMonthly === 0 && tier.id === user.organizations[0].plans[0].tierId)
        //If the customer is using the free plan
        //Get their subscription details from the DB
        if(userOnFreeTier){
            dispatch({type: GET_LATEST_PLAN_DETAILS});
            apiClient.getPlanPeriodsForPlan(userPlanId)
              .exec((err, result) => {
              if(err){
                  dispatch({type: GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL, payload: err})
              } else {
                  dispatch({type: GET_LATEST_PLAN_DETAILS_SUCCESS, payload: {startDate: moment(result[0].startDate).unix(), endDate: moment(result[0].endDate).unix()}})
                  dispatch(getTotalApiCallsForUser())
              }
            })
        }
        else {
            dispatch({type: GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS});
            apiClient.getLatestStripeSubscriptionDetails(stripeCustomerId)
              .exec((err, result) => {
                  if(err){
                      dispatch({type: GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL, payload: err})
                  } else {
                      dispatch({type: GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS, payload: result})
                      dispatch(getTotalApiCallsForUser())
                  }
              })
        }
    }
}

function getDefaultOrganizationForUser(user) {
    let defaultOrgId = user.lastUsedOrganizationId

    if(user.organizations.length > 0 && user.organizations.every(org => org.id !== defaultOrgId) || !defaultOrgId && user.organizations && user.organizations.length > 0) {
        defaultOrgId = user.organizations[0].id;
    }

    let defaultOrg
    if(defaultOrgId) {
        defaultOrg = user.organizations.find(org => org.id === defaultOrgId);
        user.role = defaultOrg.userOrganizationRelation.role
    }

    user.defaultOrgId = defaultOrgId;

    return defaultOrg;
}
