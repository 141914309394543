import {
  SET_DASHBOARD_VIEW, TOGGLE_DASHBOARD_MODAL, SET_DASHBOARD_MODAL_ERROR, SWITCH_DASHBOARD_VIEW,
  SET_DEFAULT_DASHBOARD_VIEW, SET_DEFAULT_DASHBOARD_VIEW_SUCCESS, SET_DEFAULT_DASHBOARD_VIEW_ERROR
} from '../actionTypes';


const initialState = {
  dashboard: {
    view: 'DEVELOPER',
    switch: false,
    displayModal: {
      open: false,
      loading: false,
      error: null
    }
  }
};

const preferences = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_VIEW:
      return {...state, dashboard: {...state.dashboard, view: action.payload}};
    case SWITCH_DASHBOARD_VIEW:
      return {...state, dashboard: { ...state.dashboard, switch: action.payload}};
    case TOGGLE_DASHBOARD_MODAL:
      return {...state, dashboard: { ...state.dashboard, displayModal: { ...state.dashboard.displayModal, open: !state.dashboard.displayModal.open}}};
    case SET_DEFAULT_DASHBOARD_VIEW:
      return {...state, dashboard: { ...state.dashboard, displayModal: { ...state.dashboard.displayModal, loading: true}}};
    case SET_DEFAULT_DASHBOARD_VIEW_SUCCESS:
      return {...state, dashboard: { ...state.dashboard, displayModal: { ...state.dashboard.displayModal, loading: false}}};
    case SET_DEFAULT_DASHBOARD_VIEW_ERROR:
      return {...state, dashboard: { ...state.dashboard, displayModal: { ...state.dashboard.displayModal, loading: false}}};
    case SET_DASHBOARD_MODAL_ERROR:
      return {...state, dashboard: { ...state.dashboard, displayModal: { ...state.dashboard.displayModal, error: action.payload}}};
    default:
      return state;

  }
};


export default preferences;
